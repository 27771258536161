import Requester from './request.js'
import { serverUrl } from './../utils/index'

const href = '/api'

class Api {
  // 获取用户信息
  // static getUserInfo (data = {}) {
  //   return Requester.post(`${serverUrl + href}/auth/User/getInfo`, data)
  // }

  //获取banner列表
  static getListBanner(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Banner/getList`, data)
  }


  //数据字典多条信息
  static getListTwo(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Dictionaries/getListTwo`, data)
  }

  //获取展览列表
  static getListExhibition(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Exhibition/getList`, data)
  }

  //获取展览信息
  static getInfoExhibition(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Exhibition/getInfo`, data)
  }

  // 获取藏品列表
  static getListExhibit(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Exhibit/getList`, data)
  }

  // 获取藏品列表
  static getInfoExhibit(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Exhibit/getInfo`, data)
  }

  // 获取藏品信息
  static getInfoExhibition(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Exhibition/getInfo`, data)
  }

  //获取当前藏品分类的下一个或上一个
  static getInfoTwoExhibition(data = {}) {
    return Requester.post(`${serverUrl + href}/noauth/Exhibition/getInfoTwo`, data)
  }

}

export default Api
